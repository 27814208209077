import React from "react";
const Footer = () => {
  return (
    <>
      <footer>
        <p>Copyright © 2016 OxyLoans.com. All rights reserved.</p>
      </footer>
    </>
  );
};

export default Footer;
